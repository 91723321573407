.blog {
    padding:80px  0 ;
    background: #fff;
}

.blog_items {
    display: flex;
    align-items: normal;
    justify-content: flex-start;
    margin: auto;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 50px;
}

.blog_item {
    width: 33%;
    padding: 15px;
    background-color: transparent;
    margin-bottom: 15px;
    text-align: left;
}

.blog_item .cover {
    max-width: 100%;
    height: 250px;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 10px;
    transition: all 0.1s linear;
}
.blog_item:hover .cover {
    box-shadow: 5px 5px 10px rgb(174, 174, 174);
}

.blog_item .cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.blog_item h2 {
    font-size: 18px;
}

.blog_item a {
    text-decoration: underline;
    color: #00000073;
}

.card_items {
    display: flex;
    justify-content: start;
    margin: 50px 0;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.singleBlog {
    max-width: 770px;
    margin: auto;
    text-align: left;
}

.singleBlog  p, .singleBlog  li {
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 0.1px;
    margin-top: 2em;
    margin-bottom: 0;
    font-weight: 300;
}

.singleBlog  p strong {
    font-weight: 600;
}

.singleBlog .blogTitle {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    margin-bottom: 32px;
    margin-top: 1.19em;
    letter-spacing: -0.011em;
    max-width: 80%;
}

.singleBlog  li {
    margin-top: 1.05em;
}

.singleBlog h2, .singleBlog h1 {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -0.016em;
    margin-top: 1.95em;
    margin-bottom: -0.28em;
}

.singleBlog h3 {
    font-size: 20px;
    margin-bottom: -0.31em;
    line-height: 24px;
    margin-top: 1.72em;
}

.singleBlog a {
    text-decoration: underline;
    color: inherit;
}

.singleBlog .featuredImage {
    width: 100%;
    border-radius: 10px;
}

.singleBlog .blogMeta {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.blogMetaArchive {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.singleBlog .blogMetaDetails, .blogMetaArchive .blogMetaDetails {
    display: flex;
    flex-direction: column;
}

.singleBlog .blogAuthorName, .blogMetaArchive .blogAuthorName {
    font-weight: 600;
}

.singleBlog .blogPublishedDate, .blogMetaArchive .blogPublishedDate {
    margin-left: auto;
    display: flex;
    gap: 4px;
    color: #00000073;
}


.singleBlog .blogMetaLarge {
    padding: 50px;
    background-color: #f5f5f5;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    margin-left: -50px;
    margin-right: -50px;
    border-radius: 15px;
    cursor: pointer;
    text-decoration: none;
}

.singleBlog .blogMetaLarge .blogAuthorName {
    margin-top: 20px;
}

.singleBlog .blogMetaLarge .blogAuthorTitle {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

.singleBlog .blogAuthorTitle {
    max-width: 250px;
}

.singleBlog .blogMetaLarge .blogAuthorBio {
    font-size: 14px;
    line-height: 22x;
}

.singleBlog .blogMetaLarge .profileLinks {
    display: flex;
    margin: 0;
    gap: 12px;
    flex-wrap: wrap;
}

.singleBlog .blogMetaLarge .profileLinks img {
    max-width: 16px;
}

.singleBlog .blogMetaLarge .blogMetaLargeSubTitle {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 15px 0;
}

@media (max-width: 768px){
    .blog {
        padding:40px  0 ;
    }

    .blog_item {
        width: 100%;
    }
    
    .singleBlog  p, .singleBlog  li {
        font-size: 18px;
        margin-top: 1.56rem;
        line-height: 28px;
    }

    .singleBlog {
        padding-left: 15px;
        padding-right: 15px;
    }

    .singleBlog .blogTitle {
        font-size: 32px;
        font-weight: 800;
        line-height: 38px;
        margin-bottom: 32px;
        margin-top: 1.01em;
        letter-spacing: -0.014em;
        max-width: 90%;
    }

    .singleBlog .blogMetaLarge {
        padding: 50px 25px;
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 0;
    }

    .singleBlog .blogPublishedDate, .blogMetaArchive .blogPublishedDate {
        flex-direction: column;
        text-align: right;
    }

    .singleBlog .blogAuthorTitle {
        max-width: 150px;
    }
}
