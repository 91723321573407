@primary-color: #00C08A; 
@link-color: #1890ff; 
@success-color: #52c41a; 
@warning-color: #faad14; 
@error-color: #f5222d; 
@font-size-base: 14px; 
@heading-color: rgba(0, 0, 0, 0.85); 
@text-color: rgba(0, 0, 0, 0.65); 
@text-color-secondary: rgba(0, 0, 0, 0.45); 
@disabled-color: rgba(0, 0, 0, 0.25); 
@border-radius-base: 2px; 
@border-color-base: #d9d9d9; 
@box-shadow-base: 0 3px 6px -4px, 0 6px 16px 0, 0 9px 28px 8px ; 



body {
    font-family: 'Inter', sans-serif;
    text-align: center;
    color: #00130e;
    font-size: 14px;
}

img {
    display: block;
    max-width: 100%;
}

.ant-image-preview-img {
    display: initial;
}

.logo {
    width: 150px;
    height: 150px;
}

#main {
    padding: 50px 0;
}

#main .card {
    justify-content: flex-start;
}

.ant-notification-notice-icon {
    left: 20px;
}
.ant-notification-notice-message,
.ant-notification-notice-description {
    text-align: left;
}

.contained-row {
    max-width: 1280px;
    margin: auto;
}

.single-blog {
    max-width: 770px;
    margin: auto;
    text-align: left;
    padding: 50px 20px;
}

.single-blog h2 {
    font-size: 1.2em;
}

.single-blog .featured-image {
    margin-bottom: 20px;
}

.event .ant-tabs-tab {
    background-color: transparent;
    border-radius: 0;
    text-align: center;
    padding: 20px;
    font-weight: bold;
}

.event .ant-tabs-tab-active {
    background-color: transparent;
}

.event .ant-tabs-tab:hover {
    color: #E5472D;
}

.event .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #E5472D;
}

.event .ant-tabs-nav {
    margin-bottom: 0 !important;
}

.ant-form-item-control-input-content {
    text-align: left;
}

.ant-btn-primary, .ant-btn-primary:focus {
    background: #00c087;
    border-color: #00c087;
    border-radius: 25px;
    text-align: center;
}

.ant-btn-primary:hover {
    color: #00c087;
    background: transparent;
    border-color: #00c087;
}

.ant-btn-orange, .ant-btn-orange:focus {
    background: #fc8900;
    border-color: #fc8900;
    border-radius: 25px;
    text-transform: uppercase;
    padding-right: 35px;
    padding-left: 35px;
    height: 42px;
    max-width: max-content;
    color: #ffffff;
    font-weight: 700;
}

.ant-btn-orange:hover {
    color: #fc8900;
    background: transparent;
    border-color: #fc8900;
}

.ant-typography .text-green {
    color: #00c087;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #00c087;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00c087;
    border-color: #00c087;
}

.btn_default, .btn_default:focus {
    border-radius: 20px;
    padding: 4px 40px;
    background: #00130e;
    border: 1px solid #00130e;
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.btn_default:hover {
    background: transparent;
    border: 1px solid #00130e;
    color: #00130e;
}

section.grey {
    background-color: #FAFAFA;
}

section .section-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: #00130e;
}

section .section-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #222927;
}

.ant-input, 
.ant-input-number-input, 
.ant-select .ant-select-selector,
.ant-picker {
    background: #f4f4f4 !important;
    border-radius: 5px !important;
    border: none !important;
    padding: 8px 10px 8px 30px !important;
    line-height: 30px;
    min-height: 46px;
}

.ant-select .ant-select-selector  {
    height: 46px !important;
}

.ant-select-item-option-content {
    text-align: left;
}

.ant-form-item-explain {
    margin-top: 5px;
    text-align: left;
}

.ant-form-item-control {
    margin-bottom: 5px;
}

::-webkit-input-placeholder { /* Edge */
    color: #00130e;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #00130e;
}

::placeholder {
    color: #00130e;
}

.ant-avatar-group .ant-avatar {
    border: 3px solid #fff;
}

.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: #f4f4f4;
    border-color: #ff4d4f;
}

.ant-row {
    width: 100%;
}

.subscribe_btn .ant-form-item-control-input-content {
    text-align: right;
}

.mb-5 {
    margin-bottom: 50px;
}

.mb-3 {
    margin-bottom: 30px;
}

.ant-card-cover {
    height: 160px;
    overflow: hidden;
}

.ant-card-cover img {
    object-fit: contain;
    object-position: center;
    transform: scale(1);
}

.single-blog .ant-form .ant-select .ant-select-selector {
    background: #f4f4f4 !important;
    border-radius: 25px;
    border: none;
    padding: 6px 0 8px 30px;
    line-height: 1.5715;
    height: 40px;
}

.single-blog .ant-form .ant-select .ant-select-selector input {
    border-radius: 25px;
    border: none;
    padding: 6px 0 8px 15px;
    line-height: 1.5715;
    height: 40px;
}

#main .ant-card,#community .ant-card{
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #eee;
  box-shadow: 5px 5px 5px #eee;
  justify-content: space-between;
}
#main .ant-card-meta-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: #222927;
}
#main  .ant-card-meta-description {
    color: #222927;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 14px;
}


#blog .ant-card {
  border-radius: 30px;
  overflow: hidden;
}
#blog .ant-card-meta-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: #222927;
}
#blog  .ant-card-meta-description {
    color: #222927;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 14px;
}
#community .ant-card-meta-title{
    font-size: 36px;
    font-weight: 700;
    word-spacing: 500px;
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
    line-height: 40px;
    color: #ffffff;
    margin-top: -60px;
    position: relative;
    z-index: 9;
}
#community .ant-card-cover {
    height: 250px;
}
#community  .ant-card-meta-description {
    color: #ffffff;
    padding: 5px 14px;
}
#community .card_blue{
    background: #4395f8;
}
#community .card_blue .custom-heading:after{
    -webkit-text-stroke: 14px #4395f8;
}#community .ant-card-meta-detail{
    margin-bottom: 30px;
}
.card_blue button{
    color: #4395f8;
}
#community .card_green{
    background: #00c087;
}
#community .card_green .custom-heading:after{
    -webkit-text-stroke: 14px #00c087;
}
.card_green button{
    color: #00c087;
}
#community .card_orange{
    background: #f88d0e;
}
#community .card_orange .custom-heading:after{
    -webkit-text-stroke: 14px #f88d0e;
}
.card_orange button{
    color: #f88d0e;
}
#community .ant-card-meta-detail{
    overflow: initial;
}

#pressrelease {
    padding: 50px 0;
}

#pressrelease .ant-card-cover {
    height: 120px;
    display: flex;
    justify-content: center;
}


#pressrelease .ant-card-meta-title{
    font-size: 16px;
}
#pressrelease .ant-card-meta-description {
    font-size: 14px;
}
#pressrelease .ant-card-body {
    text-align: left;
}

#boardmembers .ant-card-cover {
    height: 300px;
}

#boardmembers .ant-card-body {
    padding: 25px 15px;
}

.ant-collapse-header {
    text-align: left;
}

.App a.active{
    color: #222927;
}

.dashboard a.active {
    color: #00c087;
}

.menu_overlay .ant-dropdown-menu {
    margin-top: 20px;
    padding: 17px 17px 7px 17px;
}
.menu_overlay .ant-dropdown-menu-item {
    margin-bottom: 10px;
}
.menu_overlay .ant-dropdown-menu a {
    padding: 5px 10px;
    position: relative;
}
.menu_overlay .ant-dropdown-menu a.active:after {
    left: 10px;
    top: 30px;
}
a.active:after{
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    height: 3px;
    top: 40px;
    left: 15px;
    background: #00c087;
}
.mobile-menu a.active{
    color: #00c087;
}
#liability{
    padding: 100px 0;
}
.liability-shop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 120px;
}
#liability .ant-list-item-meta-title{
    margin-bottom: 0;
}
#liability .ant-list-item-meta{
    align-items: center;
}
.ant-card-body{
    display: flex;
    flex-direction: column;
}
.ant-input-number-lg{
    width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff !important
}

.ant-alert-message a {
    color: rgba(0, 0, 0, 0.85);
}

.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm-body .ant-modal-confirm-content {
    text-align: left;
}

@media (max-width: 992px) {
    #liability *{
        text-align: center !important;
    }
     #liability ul .ant-list-item-meta-content{
        width: max-content;
    }
    #liability ul {
        max-width: max-content;
        margin: 0 auto;
    }
    #community .ant-card-cover {
        height: 200px;
    }
}
@media (max-width: 768px) {
    .ant-list-item {
        padding: 3px 0;
    }
    .liability-shop{
        margin-top: 30px;
    }
    #liability{
        padding: 40px 0;
    }
    .ant-list-item-meta-avatar img {
        margin: 0;
    }

    section .section-title {
        font-size: 28px;
        line-height: 35px;
    }

    section .section-text {
        font-size: 14px;
        line-height: 20px;
    }
    #blog .ant-card-meta-title {
        font-size: 16px;
    }
    #blog  .ant-card-meta-description {
        font-size: 14px;
        line-height: 18px;
        padding: 0;
    }
    #community .ant-card-meta-title{
        font-size: 28px;
        line-height: 34px;
        margin-top: -53px;
    }
    #community .ant-card-cover {
        height: 150px;
    }

    .ant-drawer-body {
        padding: 0;
    }

    .ant-drawer-title {
        text-align: right;
    }

    .dashboard-sidebar .ant-drawer-title {
        text-align: left;
    }

    .ant-drawer-body  a.active:after{
        content: '';
        position: absolute;
        width: calc(100% - 30px);
        height: 3px;
        top: 50px;
        left: 15px;
        max-width: 20px;
        background: #00c087;
    }

    .dashboard-sidebar {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 20px;
    }

}



/*  Freelance fest */

.ant-tabs-nav {
    margin-bottom: 60px !important;
}

.ant-tabs-nav-list, .ant-tabs-tab-btn, .ant-tabs-tab{
    width: 100%;
}

.ant-tabs-tab-active {
    background-color: #E5472D;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.ant-tabs-tab-active span {
    color: #fff;
}