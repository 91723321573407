.statistics{
    padding: 80px 0;
    text-align: left;
    background: #fff8e6;
}
.statistics_heading{}
@media (max-width: 1200px){
    .statistics{
    text-align: center;
}
    .statistics_heading{
    margin-bottom: 30px;
}
}
@media (max-width: 786px){
    .statistics{
        padding: 40px 0;
    }
}
