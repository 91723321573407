.memberProfileHeader {
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	margin-top: 30px;
}

.memberProfileHeaderPublic {
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	margin-top: 00px;
}

.avatar {
	margin-right: 30px;
	flex-basis: 10%;
}

.detail {
	text-align: left;
	flex-basis: 70%;
}

.memberProfileHeaderPublic .detail {
	flex-basis: 60%;
	width: 60%;
}

.hourly_rate {
	margin-left: auto;
	flex-basis: 10%;
}

.memberProfileHeaderPublic .hourly_rate {
	margin-left: 0;
	flex-basis: auto;
}

.hourly_rate h2 {
	font-weight: bold;
}

.memberCard {
	margin: 20px 0;
	display: block;
}

.memberCardPublic {
	margin: 20px 0;
	display: block;
	border: 1px solid #eee;
    padding: 35px 35px;
    box-shadow: 2px 2px 5px #eee;
    border-radius: 15px;
}

.memberProfileHeader h1 {
	font-size: 26px;
    margin-bottom: 0;
    line-height: 26px;
    font-weight: 500;
    text-transform: capitalize;
}

.memberProfileHeader h2, .memberProfileHeaderPublic h2 {
	margin: 0;
	text-transform: capitalize;
}

.memberProfileHeader p {
	margin-bottom: 0;
}

.sidebar {
	padding-right: 15px;
	border-right: 1px solid rgba(0, 0, 0, 0.06);
	margin-right: 15px;
}

.member_detail {
	margin-bottom: 10px;
}

.member_detail h4 {
	font-size: 16px;
	font-weight: 500;
	text-align: left;
	line-height: 25px;
	margin-top: 5px;
	margin-bottom: 10px;
}

.member_detail p {
	text-transform: capitalize;
	text-align: left;
}

.memberTitle {
	max-width: 250px;
}

.memberProfileOverview, .memberProfileOverviewPublic {
	text-align: left;
}


.memberProfileOverviewPublic .skill {
	max-width: 150px;
}

.overviewHead {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.skills {
	margin-bottom: 10px;
}

.overviewHead h2,
.overviewHead h3 {
	margin: 0;
	font-weight: bold;
	font-size: 22px;
}

.overview {
	white-space: pre-line;
}

.typoExpand {
	color: #fc8900;
	cursor: pointer;
}

.profileLinks {
	display: flex;
	margin: 20px 0;
	gap: 12px;
	flex-wrap: wrap;
	padding-bottom: 5px;
}

.memberProfileOverviewPublic .profileLinks {
	flex-wrap: nowrap;
	overflow: hidden;
}

.profileLinks a {
	border: 1px solid #eee;
    padding: 7px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #eee;
}

.profileLinks img {
	max-width: 25px;
}

.getInTouch {
	margin: 20px 0;
}

.getInTouch button {
	box-shadow: 10px 10px 10px #eee;
}

.memberEducation {
	text-align: left;
}

.institute {
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 0;
}

.study {
	text-transform: capitalize;
	color: #0009;
}



@media (max-width: 768px) {
	.memberProfileHeader {
		flex-direction: column;
		text-align: center;
		margin-bottom: 20px;
	}

	.memberProfileHeaderPublic {
		gap: 15px;
	}
	.memberProfileHeaderPublic .detail {
		flex-basis: auto;
		text-align: left;
	}
	.sidebar {
		padding: 0;
		margin: 0;
	}
	.avatar {
		margin-right: 0;
		margin-bottom: 20px;
		flex-basis: 0;
	}
	.detail {
		text-align: center;
		flex-basis: 0;
	}
	.memberProfileHeader h1, .memberProfileHeader h2 {
		text-align: center;
	}

	.mobileDivdier {
	    width: calc(100% + 30px);
	    min-width: 100%;
	    margin: 0 -15px;
	    border: 4px solid #eee !important;
	}

	a:last-child .mobileDivdier {
		display: none;
	}

	.memberProfileHeader h2, .hourly_rate {
		margin-left: inherit;
		margin-top: 5px;
	}

	.memberProfileOverview {
		padding-bottom: 20px;
		margin-top: 20px;
	}
	.memberEducation {
		margin-top: 20px;
	}
	.member_detail {
		margin-top: 20px;
	}
	.skills span {
		margin-bottom: 5px;
	}
}

