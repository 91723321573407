.section{
    padding:  100px 0;
}

.section_title_container {
    max-width: 70%;
}

.featuredswiperContainer {
    padding: 0 50px;
}

.featuredLogo {
    min-height: 140px;
    max-height: 140px;
    display: flex;
    align-items: center;
    background-color: #fff;
}

.featuredTitle {
    font-size: 28px !important;
    line-height: 48px !important;
    margin: 0;
    margin: 50px 0;
}

.pr_items {
    display: flex;
    align-items: normal;
    justify-content: flex-start;
    max-width: 1280px;
    margin: auto;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 50px;
}

.pr_item {
    width: 33%;
    padding: 15px;
    background-color: transparent;
    margin-bottom: 15px;
}

.pr_details {
    text-align: left;
    margin: 15px 0;
}

.pr_details a {
    text-decoration: underline;
    color: #00000073;
}

.pr_details .Prtext {
    color: #00000073;
}

.pr_details h5 {
    color: #F04823;
}

.cover {
    max-width: 100%;
    height: 250px;
    overflow: hidden;
}

.cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cover_video {
    height: auto;
}

.highlight_item_inner {
    display: flex;
    column-gap: 40px;
    margin-top: 50px;
    align-items: center;
}

.section.highlights {
    background-color: #00130e;
}

.section.highlights h1,
.section.highlights h2,
.section.highlights h3,
.section.highlights h4,
.section.highlights h5 {
    color: #fff;
}

.section.highlights h3 {
    font-size: 35px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 10px;
}

.section.highlights h4 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #eee;
    line-height: 20px;
}

.section.highlights h5 {
    font-size: 18px;
    color: #eee;
}

.section.highlights p {
    color: #eee;
    margin-top: 20px;
}

.swiperContainer {
    position: relative;
    margin-left: -50px;
    margin-right: -50px;
    margin-top: 30px;
}

.swiperContainer .swiperSlider {
    padding: 0 50px;
}

.videoPlayer {
    z-index: -5;
    pointer-events: none;
    height: 150px !important;
    width: 100% !important;
}

.focusVideoPlayer {
    min-width: 100%;
    min-height: 600px;
    z-index: 10;
    pointer-events: all;
}

.focuscover_video {
    width: 100%;
}

.highlights {
    display: flex;
    column-gap: 40px;
    flex-wrap: wrap;
}

.highlight {
    margin: 20px 0;
    cursor: pointer;
}

.highlight_details {
    margin-top: 10px;
    text-align: left;
}

.highlight .highlight_details h3 {
    font-size: 16px;
    margin: 0;
    line-height: 17px;
    margin-bottom: 5px;
    color: #eee;
}

.highlight .highlight_details h4 {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    color: #ccc;
}

.highlight .highlight_details h5 {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    color: #ccc;
}

.partners {
    background-color: #f5f5f5;
}

.partner_items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1280px;
    margin: auto;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 50px;
}

.viewMorePartners {
    font-weight: bold;
    color: #fc8900;
    cursor: pointer;
    display: block;
    text-align: left;
    text-decoration: underline;
}

.viewAll {
    font-weight: bold;
    color: #fc8900;
    cursor: pointer;
    display: block;
    text-align: left;
    text-decoration: underline;
}


@media (max-width: 768px) { 
    .section{
        padding:  50px 0;
    }
    .section_title_container {
        max-width: 100%;
    }
    .pr_item {
        width: 100%;
    }

    .swiperContainer .swiperSlider {
        padding: 0 80px;
    }

    .swiperContainer .swiperleftnav {
        left: 40px;
    }

    .swiperContainer .swiperrighttnav {
        right: 40px;
    }

    .videoPlayer {
        height: 100px !important;
    }

    .highlight_item_inner {
        flex-direction: column;
    }

    .focusVideoPlayer,
    .focuscover_video {
        max-width: 100%;
        height: 190px !important;
        min-width: 100%;
        min-height: 300px;
    }

    .featuredswiperContainer {
        padding: 0;
    }

    .featuredTitle {
        font-size: 25px !important;
        line-height: 35px !important;
        margin: 0 !important;
        text-align: center;
    }

    .featured {
        padding: 20px 0;
    }
}
