.header {
    padding: 20px 0;
    background: #edfbf7;
    position: relative;
}

.header_wrapper {
    display: flex;
    align-items: center;
}

.header_logo {
    margin-right: 50px;
}

.dashboard_logo {
    max-height: 150px;
    width: 100%;
}

.menu_list {
    display: flex;
    list-style: none;
    height: 100%;
    align-items: center;
    margin: 0;
    padding: 0 10px;
}

.eventNavItem a {
    color: #F04823 !important;
}

.menu_desktop .menu_item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    position: relative;
}

.menu_item a {
    font-size: 12px;
    text-transform: uppercase;
    color: #757575;
    font-weight: 700;
}

.menu_mobile {
    display: none;
}

.header_btn {
    margin-left: auto;
}

.menu_modal {
    display: none;
}

.profileMenuContainer {
    position: absolute;
    right: 0;
}

.profileMenuContainer .profileMenu {
    background-color: transparent;
    line-height: 20px;
    border-bottom: 0;
    position: relative;
}

.profileMenuContainer .profileMenu  .profileMenuInner {
    text-align: center;
    border-bottom: none;
    float: right;
    margin: 0;
}

.profileMenuInner span {
    float: left;
    margin-right: 15px;
    line-height: 20px;
    vertical-align: middle; 
    font-weight: bold   
}

.profileMenuInner:hover,
.profileMenu .profileMenuIcon:hover,
.profileMenuInner span:hover,
.profileMenuInner > div {
    color: #00C08A !important;
}

.profileMenuContainer .profileMenu .profileMenuInner:hover {
    color: #00C08A;
    border-bottom: none;
}

.profileMenu .profileMenuIcon {
    font-size: 15px;
    line-height: 15px;
    right: 0;
    height: 20px;
    top: 0;
    color: #00c087;
}

.profileMenuIcon {
    margin: 0;
    text-align: center;
}

.profileMenu .profileMenuText {
    line-height: 35px;
    color: #00c087;
}

.header_menu_login {
    font-size: 12px;
    text-transform: uppercase;
    color: #757575;
    font-weight: 700;
    margin-right: 20px;
}



@media (max-width: 992px) {
    .header_logo {
        margin: 0 20px;
        max-width: 150px;
    }
    .header_logo img {
        width: 200px;
        height: 150px;
    }
}
@media (max-width: 768px) {

        .header {
            padding: 0;
        }
    .menu_desktop {
        display: none;
    }

    .menu_mobile {
        display: block;
        text-align: left;
    }

    .menu_mobile .menu_item {
        margin-bottom: 7px;
    }

    .menu_mobile hr {
        margin: 30px 0 10px 0;
    }

    .menu_mobile .ant-drawer-header {
        text-align: right;
    }

    .menu_icon svg {
        width: 20px;
        height: 20px;
    }

    .menu_modal {
        display: block;
    }

    .menu_mobile {
        display: block;
        text-align: left;
        padding: 0;
    }

    .menu_mobile .menu_item {
        margin-bottom: 7px;
        padding: 20px 15px;
        border-bottom: 1px solid #eee;
        position: relative;
    }

    .menu_mobile .menu_item:last-child {
        border-bottom: 0;
    }

    .profileMenuContainer .profileMenu  .profileMenuInner {
        text-align: left;
        border-bottom: none;
        float:  none;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .profileMenuContainer {
        position: initial;
        margin-top: 20px;
    }
}
