.dashboardBody {
	background-color: #eee;
}

.leftAligned {
	text-align: left;
}

.dashboardInner {
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: center;
	flex-direction: column;
	max-width: 1100px;
	margin: auto;
}

.dashboardCompleteNotify {
	width: 100%;
	text-align: center;
	padding: 150px;
}

.dashboardHeader {
	max-width: 1100px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 20px;
}

.header {
	padding: 20px 0;
	background: #edfbf7;
	position: relative;
}

.header_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header_logo {
	margin-right: 50px;
}

.dashboard_logo {
	max-height: 130px;
	width: 100%;
}

.menu_list {
	display: flex;
	list-style: none;
	height: 100%;
	align-items: center;
	margin: 0;
	padding: 0 10px;
	margin-left: auto;
}

.eventNavItem a {
	color: #f04823 !important;
}

.menu_desktop .menu_item {
	padding: 10px 15px;
	display: flex;
	align-items: center;
	position: relative;
}

.menu_item a {
	font-size: 12px;
	text-transform: uppercase;
	color: #757575;
	font-weight: 700;
}

.menu_mobile {
	display: none;
}

.header_btn {
	margin-left: auto;
}

.menu_modal {
	display: none;
}

.profileMenuContainer .profileMenu {
	background-color: transparent;
	line-height: 20px;
	border-bottom: 0;
	position: relative;
}

.profileMenuContainer .profileMenu .profileMenuInner {
	text-align: center;
	border-bottom: none;
	float: right;
	margin: 0;
}

.profileMenuInner span {
	float: left;
	margin-right: 15px;
	line-height: 20px;
	vertical-align: middle;
	font-weight: bold;
}

.profileMenuInner:hover,
.profileMenu .profileMenuIcon:hover,
.profileMenuInner span:hover,
.profileMenuInner > div {
	color: #00c08a !important;
}

.profileMenuContainer .profileMenu .profileMenuInner:hover {
	color: #00c08a;
	border-bottom: none;
}

.profileMenu .profileMenuIcon {
	font-size: 15px;
	line-height: 15px;
	right: 0;
	height: 20px;
	top: 0;
}

.profileMenuIcon {
	margin: 0;
	text-align: center;
}

.header_menu_login {
	font-size: 12px;
	text-transform: uppercase;
	color: #757575;
	font-weight: 700;
	margin-right: 20px;
}

.dashboardSectionHeader {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
}

.dashboardSectionHeader h2 {
	font-weight: bold;
	font-size: 22px;
	margin: 0;
}

.dashboardSectionBody {
	text-align: left;
	background-color: #fff;
	box-shadow: 10px 10px 10px #eee;
	border-radius: 10px;
	border: 1px solid #eee;
	padding: 0 30px;
	overflow: hidden;
}

.package {
	width: 50%;
	float: left;
	margin-bottom: 15px;
	padding: 50px 25px;
	margin-bottom: 40px;
	border: 2px solid rgba(225, 225, 225, 0.35);
	text-align: left;
}

.package:nth-child(2) {
	background-color: #f5f5f5;
	-webkit-box-shadow: 0 0 6px rgb(10 10 10 / 10%);
	-moz-box-shadow: 0 0 6px rgba(10, 10, 10, 0.1);
	box-shadow: 0 0 6px rgb(10 10 10 / 10%);
}

.packageTitle {
	font-weight: 400;
	letter-spacing: 0;
	margin-bottom: 20px;
	font-size: 1.3125rem;
	text-transform: uppercase;
}

.package sup {
	color: #999;
	font-size: 120%;
	font-weight: 400;
	vertical-align: sub;
	margin-right: 8px;
}

.packagePrice {
	font-size: 300%;
	line-height: 30px;
	font-weight: 500;
	letter-spacing: -1.5px;
	display: block;
}

.packageDescription {
	margin-top: 10px;
	margin-bottom: 30px;
	font-size: 0.95rem;
	line-height: 1.3;
}

.package_button,
.package_button:focus,
.package_button[disabled] {
	width: 100%;
	padding: 0.75rem 0 !important;
	margin-top: 15px;
	margin-bottom: 35px;
	display: block;
	text-align: center;
	background-color: transparent;
	color: #fff;
	border-radius: 5px !important;
	background-color: #00c087;
	border-color: #00c087;
}

.package_button.active {
	background-color: #00130e;
	color: #fff;
}

.package_button:hover {
	background-color: #00c087;
	border-color: #00c087;
	color: #fff;
	box-shadow: 3px 3px 10px #00c087;
}

.pricingPlan {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.pricing {
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.package hr {
	border-color: #f7f6f6;
	width: 100%;
	color: #aaa;
	background-color: #aaa;
	opacity: 0.5;
}

.packages .features {
	padding: 25px 0;
	border-top: 1px solid #aaa;
	width: 100%;
}

.package .feature {
	color: #000;
	font-size: 0.95rem;
	line-height: 1;
	font-weight: 400;
	padding: 14px 0;
	border-bottom: 1px solid #ccc;
	position: relative;
}

.package .feature:last-child {
	border-bottom: 0;
}

.feature span {
	max-width: 70%;
	font-size: 90%;
	line-height: 125%;
}

.feature .featureTitle span {
	margin-left: 5px;
}

.featureEnabled {
	position: absolute;
	right: 6px;
	bottom: 12px;
	font-weight: 500;
	color: #00c18c;
}

.memberDiscountedStroked {
	position: relative;
	display: inline-block;
	max-width: 97px;
	line-height: 10px;
	margin-bottom: 10px;
}

.memberDiscountedStroked::after {
	top: 55%;
	right: 0;
	width: 100%;
	height: 2px;
	content: '';
	position: absolute;
	transform: rotate(5deg);
	background-color: rgba(0, 0, 0, 0.54);
}

.memberDiscountedStroked .packagePrice {
	font-size: 140%;
}

.memberDiscountedStroked sup {
	font-size: 80%;
	top: -6px;
}

.discountText {
	font-size: 11px;
	font-size: italic;
}

.membershipCard {
	max-width: 400px;
	height: 250px;
	border-radius: 12px;
	box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
	background-position: 50%;
	background-size: cover;
	border: none;
	padding: 24px;
	color: #fff;
	font-size: 16px;
	background: linear-gradient(160deg, #00c087, 19%, #026649);
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.membershipCardIcon {
	font-size: 30px;
}

.membershipId {
	font-weight: 500;
	font-size: 25px;
	margin: 10px 0;
	text-shadow: 2px 1px 3px rgb(0 0 0 / 60%);
	letter-spacing: 1px;
}

.membershipDetails {
	display: flex;
	gap: 20px;
}

.membershipDetail .heading {
	margin-bottom: 0;
	font-size: 10px;
	text-transform: uppercase;
}

.membershipDetail .detail {
	font-weight: 500;
	text-transform: capitalize;
}

.membershipDetail .detail.headline {
	font-weight: 700;
	color: #fc8900;
	cursor: pointer;
	display: block;
	text-align: left;
	text-decoration: underline;
	text-transform: inherit;
}

.membershipStatus .membershipDetail .heading {
	font-size: 12px;
}

.membershipStatus .membershipDetail .detail {
	font-size: 14px;
}

.courseThankyou {
	padding: 50px;
	text-align: left;
	font-size: 18px;
}

.courseThankyou .heading {
	display: flex;
	gap: 15px;
	align-items: center;
	margin-bottom: 20px;
}

.courseThankyou .heading h3 {
	font-size: 1.4em;
	margin-bottom: 0;
}

.courseThankyou svg {
	font-size: 2em;
	color: #00c087;
}

.courseThankyou table {
	width: 100%;
	margin-top: 25px;
}

.courseThankyou td {
	padding: 15px 20px;
	border: 1px solid #bcbcbc;
}

.orderThankyouHeader {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	gap: 15px;
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
}

.orderThankyouHeader h3 {
	margin-bottom: 0;
	font-size: 20px;
}

.orderThankyouInstructions {
	margin: 20px 0 50px 0;
}

.orderThankyouInstructions li {
	margin-bottom: 10px;
}

.orderThankyou {
	padding: 50px;
	text-align: center;
	font-size: 18px;
}

.orderThankyou svg {
	font-size: 40px;
	margin-bottom: 30px;
	color: #00c087;
}

.orderThankyouHeader {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	gap: 15px;
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
}

.orderThankyouHeader h3 {
	margin-bottom: 0;
	font-size: 20px;
}

.orderThankyouInstructions {
	margin: 20px 0 50px 0;
}

.orderThankyouInstructions li {
	margin-bottom: 10px;
}

.eventTickerHeader {
	width: 100%;
	height: 150px;
	padding-left: 15px;
	margin-bottom: 20px;
}

.eventTickerHeader img {
	max-width: 100%;
	max-height: 100%;
}

.membershipRegister {
	margin-left: -15px;
	margin-right: -15px;
	width: 100%;
	display: flex;
	margin-bottom: 30px;
}

.eventRegisterForm {
	width: 70%;
	padding-left: 15px;
	padding-right: 15px;
}

.eventDetailsWidget {
	width: 30%;
	padding-left: 15px;
	padding-right: 15px;
}

.selectedEventContainer {
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: top;
	width: 100%;
	text-align: left;
	flex-direction: column;
}

.eventCol {
	max-width: 200px;
	margin-bottom: 10px;
}

.eventPicture img {
	max-width: 100%;
}

.eventDetails {
	text-align: left;
}

.eventDetail {
	margin-bottom: 10px;
}

.eventDetailHead,
.ticketPreviewHead {
	font-weight: bold;
	display: block;
	text-transform: uppercase;
}

.eventDetailInfo {
	font-size: 1rem;
}

.dropdownValue {
	text-align: left;
}

.dropdown {
	border-radius: 5px;
}

.quiz_form .question {
	font-size: 16px;
	margin-bottom: 10px;
	width: 100%;
}

.quiz_form label::before {
	content: none !important;
}

.radioValue {
	width: 100%;
	margin-bottom: 7px;
}

.ticketHead {
	text-align: left;
	margin-bottom: 20px;
}

.stroked {
	position: relative;
	margin-right: 10px;
}

.stroked::after {
	content: '';
	top: 40%;
	right: 0;
	width: 100%;
	height: 2px;
	content: '';
	position: absolute;
	transform: rotate(5deg);
	background-color: rgba(0, 0, 0, 0.54);
}

.eventTotal {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.eventTotalHead {
	font-weight: bold;
	display: block;
	text-transform: uppercase;
}

.eventDetailInfo {
	font-size: 1rem;
	text-align: right;
}

.ticketPreview {
	border-radius: 10px;
	margin: 20px 0;
	display: flex;
	justify-content: flex-start;
	align-items: top;
	width: 100%;
	text-align: left;
	flex-direction: column;
}

.ticketPreviewCol {
	display: flex;
	margin-left: -15px;
	margin-right: -15px;
}

.ticketPreviewColInner {
	width: 50%;
	padding-left: 15px;
	padding-right: 15px;
}

.ticketPreviewDetail {
	display: flex;
	align-items: flex-start;
	font-size: 14px;
	margin-bottom: 10px;
}

.ticketPreviewColFull {
	width: 100%;
}

.ticketPreviewColFull .ticketPreviewDetail {
	padding-left: 15px;
	padding-right: 15px;
}

.ticketPreviewColFull hr {
	margin: 20px 0;
	padding-left: 15px;
	padding-right: 15px;
}

.ticketPreviewHead {
	width: 50%;
	text-align: left;
}

.ticketPreviewInfo {
	width: 50%;
	text-align: right;
}

.ticketPreviewAccordion {
	margin-bottom: 15px;
}

.shippingPreview {
	border-radius: 10px;
	margin: 20px 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	text-align: left;
	flex-direction: row;
}

.shippingPreviewCol {
	display: flex;
	width: 50%;
	flex-direction: column;
}

.shippingPreviewColInner {
	width: 50%;
	padding-left: 15px;
	padding-right: 15px;
}

.shippingPreviewDetailHead {
	font-size: 14px;
	margin-bottom: 10px;
	text-align: left;
}

.shippingPreviewDetail {
	font-size: 14px;
	margin-bottom: 10px;
	text-align: right;
}

.shippingPreviewColFull {
	width: 100%;
}

.shippingPreviewColFull .shippingPreviewDetail {
	padding-left: 15px;
	padding-right: 15px;
}

.shippingPreviewColFull hr {
	margin: 20px 0;
	padding-left: 15px;
	padding-right: 15px;
}

.shippingPreviewHead {
	width: 50%;
	text-align: left;
}

.shippingPreviewInfo {
	width: 50%;
	text-align: right;
}

.shippingPreviewAccordion {
	margin-bottom: 15px;
}

.desktop {
	display: inherit;
}

.mobile {
	display: none;
}

.eventRegisterThankyou {
	display: flex;
	align-items: center;
}

.confirmation {
	padding: 50px;
	border: 1px solid #eee;
	box-shadow: 10px 10px 10px #eee;
	border-radius: 10px;
}

.ticketPreviewButton {
	font-size: 12px;
	color: #00c087;
	font-style: italic;
	float: right;
}

.dashboardSectionSidebar {
	text-align: left;
}

.dashboardSectionSidebar ul {
	background-color: transparent;
}

.dashboardInner .dashboardSectionSidebar ul li {
	height: auto;
	line-height: 25px;
}

.dashboardSectionSidebar p {
	text-align: left;
	margin-bottom: 10px;
	margin-left: 10px;
	color: #333;
}

.dashboardSectionSidebar h2 {
	margin-top: 0;
	margin-bottom: 15px;
}

.filter {
	text-align: left;
	margin-bottom: 20px;
	margin-left: 10px;
}

.filter h3 {
	margin-bottom: 10px;
}

.filter .filterItem {
	display: flex;
	margin: 0;
	margin-bottom: 7px;
}

.contribtue_form,
.quiz_form {
	margin: 30px 0;
}

@media (max-width: 992px) {
	.header_logo {
		margin: 0 20px;
		max-width: 150px;
	}
	.header_logo img {
		width: 200px;
		height: 150px;
	}
}

@media (max-width: 768px) {
	.menu_desktop {
		display: none;
	}

	.menu_mobile {
		display: flex;
		text-align: left;
		padding: 0;
		padding-bottom: 100px;
		flex-direction: column;
		align-items: baseline;
		height: auto;
	}

	.menu_mobile .menu_item {
		margin-bottom: 7px;
		padding: 20px 15px 0;
		border-bottom: 1px solid #eee;
		position: relative;
		width: 100%;
	}

	.menu_mobile hr {
		margin: 30px 0 10px 0;
	}

	.menu_mobile .ant-drawer-header {
		text-align: right;
	}

	.menu_icon svg {
		width: 20px;
		height: 20px;
	}

	.menu_modal {
		display: block;
	}

	.sign_up {
		display: block;
		padding: 20px;
	}

	.eventRegister {
		flex-direction: column;
		margin: 0;
	}

	.eventRegisterForm {
		width: 100%;
		padding: 0;
		margin-bottom: 80px;
	}

	.eventDetailsWidget {
		width: 100%;
		padding: 0;
		position: fixed;
		background-color: #fff;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.selectedEventContainer {
		border-radius: 0;
		padding: 15px 6px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.eventDetailInfo {
		font-weight: normal;
		text-transform: initial;
	}

	.desktop {
		display: none;
	}

	.mobile {
		display: inherit;
	}

	.ticketPreview {
		margin: 0;
	}

	.ticketPreviewCol {
		flex-direction: column;
	}

	.ticketPreviewColInner {
		width: 100%;
		margin-bottom: 10px;
	}

	.ticketPreviewHead,
	.ticketPreviewInfo {
		font-size: 12px;
	}

	.ticketPreviewColFull hr {
		margin: 0 0 10px 0;
	}

	.membershipId {
		font-size: 16px;
	}

	.membershipCard {
		height: 200px;
		gap: 10px;
		margin-bottom: 30px;
	}

	.package {
		width: 100%;
		padding: 25px;
	}

	.dashboardSectionBody {
		margin: 0;
		padding: 0 15px;
	}

	.pricing {
		height: auto;
	}

	.dashboardCompleteNotify {
		padding: 150px 0;
	}

	.header_wrapper {
		justify-content: flex-start;
	}

	.dashboardFilter {
		float: right;
	}

	.mobileMenu {
		margin-top: 20px;
		border: 0;
	}

	.profileMenuContainer {
		right: 0;
		margin-left: auto;
	}

	.filter {
		padding: 10px 20px;
	}

	.filter h3 {
		margin-bottom: 20px;
	}

	.filter .filterItem {
		margin-bottom: 15px;
		margin-left: 5px;
	}
}
