.gccHalfHeading {
	font-size: 40px;
	width: 70%;
	margin-bottom: 50px;
	margin-top: 0;
}

.checklist {
	border-left: 1px dashed #333;
	margin-bottom: 60px;
}

.checklistItem {
	font-size: 18px;
	padding-bottom: 60px;
	padding-left: 40px;
	position: relative;
}

.checkIcon {
	width: 40px;
	height: 40px;
	position: absolute;
	left: -20px;
	z-index: 20;
	background-size: 100%;
	top: -5px;
	color: #fc8900;
	overflow: hidden;
	background-color: #f5f5f5;
}

.checklistItem:last-child {
	padding-bottom: 0;
}

.caseStudiesSection {
	background-color: #f5f5f5;
}

.case_study {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 50px;
}

.case_study > div {
	flex-basis: calc(50% - 50px);
}

.case_study_details {
	text-align: left;
}

.videoPlayer {
	min-height: 300px;
	max-height: 300px;
	max-width: 100%;
}

.bgvideoPlayer {
	max-width: 100% !important;
	display: flex;
	align-items: center;
	max-height: 100% !important;
	overflow: hidden;
}

.bgvideoPlayer video {
	position: relative;
	right: -1px;
	bottom: -1px;
}

.case_study_navigation {
	margin-top: 30px;
	display: flex;
	gap: 15px;
}

.swiperleftnav,
.swiperrighttnav {
	position: relative;
	color: #333;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: auto;
	height: auto;
	margin: 0;
}

.swiperleftnav::after,
.swiperrighttnav::after {
	color: #333;
	font-size: 20px;
}

.partner_items {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 1280px;
	margin: auto;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 50px;
}

@media (max-width: 768px) {
	.gccHalfHeading {
		font-size: 30px;
		width: 100%;
	}

	.checklist {
		margin: 0 30px;
		margin-bottom: 60px;
	}

	.case_study {
		flex-direction: column;
		margin-bottom: 50px;
		gap: 20px;
	}

	.case_study > div {
		max-width: 100%;
	}

	.videoPlayer {
		min-height: auto;
		max-width: 100%;
		max-height: 300px;
	}
}
