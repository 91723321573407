.footer {
    padding: 50px 0;
}

.footer .text_bottom a {
    margin-right: 10px;
    text-decoration: underline;
}

.footer .text_bottom a:last-child {
    margin-right: 0;
}

.footer_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.footer_logo {
    margin: 0 auto;
    width: 200px;
    height: 200px;
}

.footer_left {
    text-align: left;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text_top {
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
    color: #00130e;
}

.text_bottom ,.text_bottom a{
    color: #222927;
    font-weight: 400;
}
.footer_right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    font-size: 14px;
}
.footer_social{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}
.footer_social_item{
    margin-left: 10px;
}

.footer_social_item svg {
    color: #ababab;
    font-size: 30px;
    border: 1px solid #9d9b9b;
    padding: 5px;
    border-radius: 5px;
}

.copyright{
    color: #222927;
    font-size: 12px;
    display: block;
    margin-top: 10px;
}
@media (max-width: 768px) {
    .footer_left,.footer_right{
        text-align: center !important;
        margin-bottom: 15px;
    }
    .footer_right{
        width: 100%;
    }
    .footer_logo{
        margin:0 auto 15px;
    }
    .footer_logo_wrapper{
        width: 100%;
    }
    .footer_social{
        justify-content: center;
    }
}
