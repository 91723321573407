.banner{
    background-color: #edfbf7;
    background-size: cover;
    background-position: bottom;

}
.banner_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.banner_right{
     display: flex;
     justify-content: flex-end;
     align-items: flex-end;
 }
.img_center{
    align-items: center;
}
h1.banner_title{
    font-size: 42px;
    letter-spacing: -1px;
    font-weight: 800;
    line-height: 60px;
    margin-bottom: 35px;
}
div.banner_text{
    font-size: 16px;
    font-weight: 700;
    color: #222927;
    margin-bottom: 40px;
}
@media (max-width: 768px){
    .banner_right{
        display: none;
    }
    h1.banner_title{
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }
    div.banner_text{
        font-size: 14px;
    }
    .banner_left{
        padding: 10px 0;
    }
}
