.quizTable {
	border-collapse: collapse;
	width: 70%;
	margin: 30px 0;
}
.quizTable,
.quizTable th,
.quizTable td {
	border: 1px solid black;
}
.quizTable th,
.quizTable td {
	padding: 10px;
	text-align: left;
}
.quizTable tr:nth-child(even) {
	background-color: #f2f2f2;
}
.quizTable th {
	font-weight: bold;
}

.quizBtn {
	text-align: center;
	max-width: 200px;
}

.quizBtnContainer {
	text-align: center;
	justify-content: center;
	margin: 40px 0 25px 0 !important;
	gap: 0;
}

.quizResultHeading {
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	margin: 20px auto;
	line-height: 50px;
	max-width: 60%;
}

.quizResultTable {
	margin: 30px auto;
}

.quizResultIcon {
	color: #00c08a !important;
	font-size: 70px;
	margin: 20px auto;
	margin-top: 70px;
	line-height: 50px;
	max-width: 60%;
	text-align: center;
}

.quizResultIconFailed {
	color: maroon !important;
}

.quizResultBtn {
	cursor: pointer;
	padding: 10px 20px;
}

.certificateButton {
	cursor: pointer;
	background-color: #00c08a !important;
	color: #fff !important;
	font-size: 14px;
	margin-bottom: 20px;
	display: inline-block;
	border-color: #00c08a !important;
	box-shadow: 5px 10px 10px #eee;
}

.courseCertificateButton {
	width: 100%;
	text-align: center;
	box-shadow: none;
	margin-bottom: 0;
	text-transform: none;
}

.quizResultFeedbackForm {
	max-width: 740px;
	cursor: pointer;
	margin: auto;
	padding: 10px;
}

.quizResultFeedbackForm svg {
	font-size: 16px;
	margin: 0 5px;
}
