.card_items {
	display: flex;
	justify-content: start;
	margin: 50px 0;
	flex-wrap: wrap;
	gap: 15px;
}

.eventCard {
	border-radius: 0 !important;
	margin-bottom: 50px;
	width: calc(33.333% - 15px);
	margin: 0;
}

.events > div > div:nth-child(1) {
	height: auto;
	max-height: 250px;
}

.events img {
	object-fit: cover;
	width: auto;
}

.eventCard .ant-card-body {
	font-size: 50px;
}

.bodyclass {
	text-align: left;
}

.bodyclass h3 {
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.bodyclass .date {
	margin-right: 10px;
}

.leftAligned {
	text-align: left;
}

.sign_up {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 1100px;
	margin: auto;
}

.eventTickerHeader {
	width: 100%;
	height: 150px;
	padding-left: 15px;
	margin-bottom: 20px;
}

.eventTickerHeader img {
	max-width: 100%;
	max-height: 100%;
}

.eventRegister {
	margin-left: -15px;
	margin-right: -15px;
	width: 100%;
	display: flex;
}

.eventRegisterForm {
	width: 70%;
	padding-left: 15px;
	padding-right: 15px;
}

.eventDetailsWidget {
	width: 30%;
	padding-left: 15px;
	padding-right: 15px;
}

.event_register_form {
	box-shadow: 10px 10px 10px #eee;
	border-radius: 10px;
	border: 1px solid #eee;
	padding: 30px;
}

.eventData,
.eventTabs,
.eventGallery,
.eventSidebar {
	text-align: left;
	border: 1px solid #eee;
	box-shadow: 0px 0px 3px #eee;
	margin-bottom: 40px;
	border-radius: 5px;
	background-color: #fff;
}

.eventTab {
	padding: 25px;
}

.eventContent {
	padding: 15px;
}

.eventContent h2 {
	font-weight: bold;
}

.event_register_btn,
.event_register_btn:focus,
.event_register_btn:hover {
	width: 100%;
	padding: 20px 0;
	line-height: 1px;
	background-color: #00c08a;
	border-color: #00c08a;
	color: #fff;
	text-align: center;
}

.event_register_btn_orange,
.event_register_btn_orange:focus,
.event_register_btn_orange:hover {
	width: 100%;
	padding: 20px 0;
	line-height: 1px;
	background-color: #e5472d;
	border-color: #e5472d;
	color: #fff;
	text-align: center;
}

.event_register_btn a {
	color: #fff;
}

.selectedEventContainer {
	padding: 30px;
	border: 1px solid #eee;
	box-shadow: 10px 10px 10px #eee;
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: top;
	width: 100%;
	text-align: left;
	flex-direction: column;
}

.eventCol {
	max-width: 200px;
	margin-bottom: 10px;
}

.eventPicture img {
	max-width: 100%;
}

.eventDetails {
	text-align: left;
}

.eventDetail {
	margin: 0 25px;
	display: flex;
	grid-gap: 30px;
	gap: 30px;
	border-bottom: 1px solid #eee;
	margin-bottom: 15px;
	padding-bottom: 15px;
}

.eventDetail.noBorder {
	border-bottom: 0px;
	margin-bottom: 5px;
}

.eventDetail ul {
	padding-left: 15px;
}

.eventDetail:last-child {
	border-bottom: 0;
}

.eventDetail .icon {
	font-size: 25px;
	color: #00130e;
}

.eventSidebar .header {
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
}

.eventSidebar .detail {
	font-size: 14px;
	color: #888888;
	margin-top: 5px;
}

.eventDetailHead,
.ticketPreviewHead {
	font-weight: bold;
	display: block;
	text-transform: uppercase;
}

.eventDetailInfo {
	font-size: 1rem;
}

.eventGallery {
	padding: 25px;
}

.sectionHeader {
	padding: 15px 20px;
	border-bottom: 1px solid #eee;
	font-weight: bold;
	margin-bottom: 30px;
}

.dropdownValue {
	text-align: left;
}

.dropdown {
	border-radius: 5px;
}

.ticketHead {
	text-align: left;
	margin-bottom: 20px;
}

.stroked {
	position: relative;
	margin-right: 10px;
}

.stroked::after {
	content: '';
	top: 40%;
	right: 0;
	width: 100%;
	height: 2px;
	content: '';
	position: absolute;
	transform: rotate(5deg);
	background-color: rgba(0, 0, 0, 0.54);
}

.eventTotal {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.eventTotalHead {
	font-weight: bold;
	display: block;
	text-transform: uppercase;
}

.eventDetailInfo {
	font-size: 1rem;
	text-align: right;
}

.ticketPreview {
	border-radius: 10px;
	margin: 20px 0;
	display: flex;
	justify-content: flex-start;
	align-items: top;
	width: 100%;
	text-align: left;
	flex-direction: column;
}

.ticketPreviewCol {
	display: flex;
	margin-left: -15px;
	margin-right: -15px;
}

.ticketPreviewColInner {
	width: 50%;
	padding-left: 15px;
	padding-right: 15px;
}

.ticketPreviewDetail {
	display: flex;
	align-items: flex-start;
	font-size: 14px;
	margin-bottom: 10px;
}

.ticketPreviewColFull {
	width: 100%;
}

.ticketPreviewColFull .ticketPreviewDetail {
	padding-left: 15px;
	padding-right: 15px;
}

.ticketPreviewColFull hr {
	margin: 20px 0;
	padding-left: 15px;
	padding-right: 15px;
}

.ticketPreviewHead {
	width: 50%;
	text-align: left;
}

.ticketPreviewInfo {
	width: 50%;
	text-align: right;
}

.ticketPreviewAccordion {
	margin-bottom: 15px;
}

.shippingPreview {
	border-radius: 10px;
	margin: 20px 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	text-align: left;
	flex-direction: row;
}

.shippingPreviewCol {
	display: flex;
	width: 50%;
	flex-direction: column;
}

.shippingPreviewColInner {
	width: 50%;
	padding-left: 15px;
	padding-right: 15px;
}

.shippingPreviewDetailHead {
	font-size: 14px;
	margin-bottom: 10px;
	text-align: left;
}

.shippingPreviewDetail {
	font-size: 14px;
	margin-bottom: 10px;
	text-align: right;
}

.shippingPreviewColFull {
	width: 100%;
}

.shippingPreviewColFull .shippingPreviewDetail {
	padding-left: 15px;
	padding-right: 15px;
}

.shippingPreviewColFull hr {
	margin: 20px 0;
	padding-left: 15px;
	padding-right: 15px;
}

.shippingPreviewHead {
	width: 50%;
	text-align: left;
}

.shippingPreviewInfo {
	width: 50%;
	text-align: right;
}

.shippingPreviewAccordion {
	margin-bottom: 15px;
}

.desktop {
	display: inherit;
}

.mobile {
	display: none;
}

.eventRegisterThankyou {
	display: flex;
	align-items: center;
}

.confirmation {
	padding: 50px;
	border: 1px solid #eee;
	box-shadow: 10px 10px 10px #eee;
	border-radius: 10px;
}

.ticketPreviewButton {
	font-size: 12px;
	color: #e5472d;
	font-style: italic;
	float: right;
}

.eventSpeakers {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	max-height: 600px;
	overflow-y: scroll;
}

/* width */
.eventSpeakers::-webkit-scrollbar {
	width: 5px;
}

/* Track */
.eventSpeakers::-webkit-scrollbar-track {
	background: rgb(241 241 241 / 20%);
}

/* Handle */
.eventSpeakers::-webkit-scrollbar-thumb {
	background: #00c08a;
}

/* Handle on hover */
.eventSpeakers::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.speaker {
	width: 33%;
	padding: 15px;
	background-color: transparent;
	margin-bottom: 15px;
	cursor: pointer;
}

.speaker_inner {
	position: relative;
}

.speaker:hover .cover img {
	-webkit-transform: scale(1.08) translate3d(10px, 0, 0);
	-moz-transform: scale(1.08) translate3d(10px, 0, 0);
	transform: scale(1.08) translate3d(10px, 0, 0);
	-webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: -moz-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.speaker .cover img {
	-webkit-transform: scale(1.08) translate3d(-10px, 0, 0);
	-moz-transform: scale(1.08) translate3d(-10px, 0, 0);
	transform: scale(1.08) translate3d(-10px, 0, 0);
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: -moz-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
	will-change: transform;
	filter: grayscale(100%);
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.speaker .cover {
	position: relative;
	overflow: hidden;
	border-radius: 5px;
}

.speaker .cover::before {
	content: '';
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.speaker:hover .cover::before {
	background-color: transparent;
}

.details {
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.speaker:hover .cover::after {
	opacity: 0.7;
}

.cover::after {
	content: '';
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	mix-blend-mode: multiply;
	background: #e5472d !important;
	opacity: 0;
}

.details_inner {
	position: absolute;
	padding: 15px;
	box-sizing: border-box;
	overflow: hidden;
	bottom: 0;
	width: 100%;
	color: #fff;
	z-index: 1;
}

.details_inner .title_holder,
.details_inner .social_holder {
	text-align: left;
}

.speaker:hover .title_holder,
.speaker:hover .social_holder {
	opacity: 1;
}

.title_holder .speaker_name {
	font-size: 16px;
	font-weight: bold;
}

.title_holder .speaker_type {
	font-weight: bold;
	font-size: 13px;
}

.title_holder .speaker_position {
	display: block;
	margin: 0;
	font-size: 13px;
	font-weight: 300;
}

.title_holder .speaker_company {
	display: block;
	margin: 0;
	font-size: 13px;
	font-weight: 300;
}

.social_holder {
	position: absolute;
	top: 8px;
	right: 0;
}

.gutter {
	gap: 25px;
}

.discountCard {
	width: 32%;
}

.discountCard > div:first-child {
	height: 200px;
	overflow: hidden;
	vertical-align: middle;
	display: flex;
	padding: 30px;
	background-color: #edfbf7;
}

.discountImage {
	padding: 50px;
	text-align: center;
	align-items: center;
	margin: auto;
	background-color: #edfbf7;
}

.promotionCodeField {
	padding: 15px 25px;
	font-weight: bold;
	text-align: left;
	border: 1px dashed #00c08a;
	margin-bottom: 25px;
	background-color: rgb(0 192 138 / 5%);
	border-radius: 5px;
	position: relative;
}

.copyPromotionCode {
	background-color: #fc8900;
	color: #fff;
	position: absolute;
	right: 0;
	top: 0;
	font-size: 16px;
	cursor: pointer;
	height: 100%;
	width: 25%;
	line-height: 60px;
	text-align: center;
	cursor: pointer;
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
	.sign_up {
		display: block;
		padding: 20px;
	}

	.eventCard {
		width: 100%;
	}

	.eventRegister {
		flex-direction: column;
		margin: 0;
	}

	.eventRegisterForm {
		width: 100%;
		padding: 0;
		margin-bottom: 80px;
	}

	.eventDetailsWidget {
		width: 100%;
		padding: 0;
		position: fixed;
		background-color: #fff;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.selectedEventContainer {
		border-radius: 0;
		padding: 15px 6px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.eventDetailInfo {
		font-weight: normal;
		text-transform: initial;
	}

	.desktop {
		display: none;
	}

	.mobile {
		display: inherit;
	}

	.ticketPreview {
		margin: 0;
	}

	.ticketPreviewCol {
		flex-direction: column;
	}

	.ticketPreviewColInner {
		width: 100%;
		margin-bottom: 10px;
	}

	.ticketPreviewHead,
	.ticketPreviewInfo {
		font-size: 12px;
	}

	.ticketPreviewColFull hr {
		margin: 0 0 10px 0;
	}

	.card_items {
		margin-top: 0;
	}

	.speaker .cover img {
		transform: scale(1.08) translate3d(-10px, 0, 0);
		transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
		will-change: transform;
		filter: grayscale(100%);
		width: 100%;
		width: 300px;
		height: 250px;
		object-fit: cover;
	}

	.speaker {
		width: 100%;
	}

	.eventData,
	.eventTabs,
	.eventGallery,
	.eventSidebar {
		margin-bottom: 5px;
	}

	.discountCard {
		width: 100%;
	}
}
