/*General*/

h1 {
	font-size: 3.125rem;
    text-align: left;
    font-weight: bold;
}

h2 {
	font-size: 1.2rem;
	margin-top: 1.15rem;
	text-align: left;
	font-weight: 300;
}

.form-button {
    line-height: 32px;
}

.venueDate a {
    text-trans-: uppercase;
    margin-bottom: 15px;
    margin-top: 15px;
    border-bottom: 1px solid #bdbdbd;
    display: inline-block;
    padding-bottom: 2px;
}

.banner .venueDate span {
    color: #fff;
}

.venueDate span {
    margin-right: 10px;
    color: #000;
}

.venueDate sup {
    font-size: 60%;
}

.section_title_container {
	max-width: 70%;
}


.event_register_btn {
    margin-top: 15px;
}

.event-register-btn.org {
	background-color: #E5472D;
}


.speakers {
	background-color: #f5f5f5;
}

.speaker_items {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 1280px;
	margin: auto;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 50px;
}

.speaker {
	width: 25%;
	padding: 15px;
	background-color: transparent;
	margin-bottom: 15px;
}

.speaker_inner {	
	position: relative;
}

.speaker:hover .cover img {
	-webkit-transform: scale(1.08) translate3d(10px,0,0);
    -moz-transform: scale(1.08) translate3d(10px,0,0);
    transform: scale(1.08) translate3d(10px,0,0);
    -webkit-transition: -webkit-transform 1s cubic-bezier(.23,1,.32,1);
    -moz-transition: -moz-transform 1s cubic-bezier(.23,1,.32,1);
    transition: transform 1s cubic-bezier(.23,1,.32,1);
}

.speaker .cover img {
	-webkit-transform: scale(1.08) translate3d(-10px,0,0);
    -moz-transform: scale(1.08) translate3d(-10px,0,0);
    transform: scale(1.08) translate3d(-10px,0,0);
    -webkit-transition: -webkit-transform .6s cubic-bezier(.23,1,.32,1);
    -moz-transition: -moz-transform .6s cubic-bezier(.23,1,.32,1);
    transition: transform .6s cubic-bezier(.23,1,.32,1);
    will-change: transform;
    filter: grayscale(100%);
    width: 100%;
}

.speaker .cover {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.sponsors {
    background-color: #f5f5f5;
}

.sponsor_items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1280px;
    margin: auto;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 50px;
}

.viewMorePartners {
    font-weight: bold;
    color: #fc8900;
    cursor: pointer;
    font-size: 16px;
    display: block;
    text-align: left;
}

.sponsor {
    width: 25%;
    padding: 15px;
    background-color: transparent;
    margin-bottom: 15px;
}

.sponsor_inner {    
    position: relative;
}

.sponsor_company {
    font-weight: bold;
}

.details {
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.speaker:hover .cover::after {
	opacity: .7;
}

.cover::after {
	content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
	mix-blend-mode: multiply;
	background: #E5472D !important;
	opacity: 0;

}

.details_inner {
	position: relative;
    height: 100%;
    margin: 15px 0;
    box-sizing: border-box;
    overflow: hidden;
}

.details_inner .title_holder,
.details_inner .social_holder {
	text-align: left;
}

.speaker:hover .title_holder,
.speaker:hover .social_holder {
	opacity: 1;
}

.title_holder .speaker_name {
	font-size: 22px;
	font-weight: bold;
}

.title_holder .speaker_type {
    font-weight: bold;
}

.title_holder .speaker_position {
	display: block;
	margin: 0;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: .1em;
}

.title_holder .speaker_company {
	display: block;
	margin: 0;
	font-size: 13px;
	font-weight: 300;
	letter-spacing: .1em;
}

.social_holder {
	position: absolute;
    top: 8px;
    right: 0;
}

/* Countdown Time */

.timer {
	background-color: #E5472D;
	color: #fff;
	display: flex;
}

.futurefestbg {
    background: rgb(229,71,45);
    background: linear-gradient(90deg, rgba(229,71,45,1) 30%, rgba(61,99,174,1) 78%);
    color: #fff;
    display: flex;
}

.timer .title {
	color: #fff;
	width: 30%;
}

.timer h2,
.timer h1 {
	color: #fff;
}

.timerGrid {
	display: flex;
	align-items: center;
	justify-content: center;
}

.count {
	width: 70%;
}

.countInner {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 600px;
	margin-left: auto;
}

.countDown {
	font-size: 4.5rem;
	line-height: 1;
	font-weight: 700;
	letter-spacing: -0.5px;
	width: 25%;
	border-right: 1px;
	border-color: #fff;
	border-style: solid;
	border-top: 0;
	border-bottom: 0;
	border-left: 0;
}

.countDown:last-of-type {
	border: 0;
}

.countType {
	font-size: 13px;
    line-height: 13px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    display: block;
    padding-top: 15px;
}

/* Header */


.header {
    padding: 20px 0;
    background: #E5472D;
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 10;
    overflow: hidden;
}

.headerFreelanceFestISL {
    background: rgb(229,71,45);
    background: linear-gradient(90deg, rgba(229,71,45,1) 30%, rgba(61,99,174,1) 78%);
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 10;
    overflow: hidden;
    padding: 20px 0;
}

.header .menu_item a {
	color: #fff !Important;
}

.header a::after {
	content: none;
}

.header_wrapper {
    display: flex;
    align-items: center;
}
.header_logo {
    margin-right: 50px;
}
.header_logo  a {   
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_logo img {
    max-width: 200px;
    margin-right: 15px;
}
.header_logo span {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: -1px;
}

.menu_list {
    display: flex;
    list-style: none;
    height: 100%;
    align-items: center;
    margin: 0;
    padding: 0 10px;
    margin-left: auto;
}

.eventNavItem a {
    color: #F04823 !important;
}

.menu_desktop .menu_item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    position: relative;
}

.menu_item a {
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
}

.menu_mobile {
    display: none;
}

.header_btn button, .event-register-btn {
    background-color: #fff;
    color: #000;
    border:  none;
    box-shadow: 3px 3px 10px #8d8d8d;
}

.menu_modal {
    display: none;
}

.profileMenuContainer {
    position: relative;
    right: 0;
}

.profileMenuContainer .profileMenu {
    background-color: transparent;
    line-height: 20px;
    border-bottom: 0;
    position: relative;
}

.profileMenuContainer .profileMenu  .profileMenuInner {
    text-align: center;
    border-bottom: none;
    float: right;
    margin: 0;
}

.profileMenuInner span {
    float: left;
    margin-right: 15px;
    line-height: 20px;
    vertical-align: middle; 
    font-weight: bold   
}

.profileMenuInner:hover,
.profileMenu .profileMenuIcon:hover,
.profileMenuInner span:hover,
.profileMenuInner > div {
    color: #fff !important;
}

.profileMenuContainer .profileMenu .profileMenuInner:hover {
    color: #fff;
    border-bottom: none;
}

.profileMenu .profileMenuIcon {
    font-size: 15px;
    line-height: 15px;
    right: 0;
    height: 20px;
    top: 0;
}

.profileMenuIcon {
    margin: 0;
    text-align: center;
}

.header_menu_login {
    font-size: 12px;
    text-transform: uppercase;
    color: #757575;
    font-weight: 700;
    margin-right: 20px;
}


/*  Scheudle */

.schedule {
	background-color: #343a40 !important;
	color: #fff;
}

.schedule h1 {
	color: #fff;
}

.schedule h2 {
	color: #fff;
}

.agendaContainer {
	margin-top: 70px;
}

.tabPaneHead {
	text-align: center;
    color: #fff;
    border-color: #666;
    color: #999;
    font-size: 1.15rem;
    font-weight: 500;
    letter-spacing: 0;
    border: none;
    padding: 0.35rem 0;
    width: 100%;
    display: block;
}

.ant-tabs-tab-active .tabPaneHead {
	color: #fff;
}

.tabPaneHead > .ant-tabs-nav::before {
	border-color: #666;
}

.scheduleItem {
	width: 50%;
	float: left;
	margin-bottom: 15px;
	min-height: 150px;
}

.scheduleItem.small {
	min-height: 150px;
}

.scheduleItemRow {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 15px;
}

.scheduleItemCol {
	text-align: left;
	padding: 0 15px;
	color: #fff;
}

.scheduleTimings {
	-webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.scheduleAgenda {
	-webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
    border-color: #666;
}

.scheduleAgenda hr {
    border-color: #666;
    box-shadow: none;
    margin-top: 25px;
    margin-bottom: 25px;
}

.scheduleTime {
	font-size: 1rem;
    line-height: 1.3;
    font-weight: 700;
    display: block;
    padding-left: 10px;
}

.scheduleAgendaTitle {
	font-weight: 600;
    letter-spacing: -0.5px;
    font-size: 1.375rem;
    margin-bottom: 7px;
    display: block;
}

.scheduleAgendaDescription {
    max-width: 90%;
    display: block;
}

.scheduleAgendaDescription span {
	color: #aaa;
	line-height: 1.3;
	margin-top: 8px;
}

/* Tickets  */

.tickets {
	background-color: #fff;
}

.packages {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 1280px;
	margin: auto;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 50px;
}

.package {
	width: 33%;
	float: left;
	margin-bottom: 15px;
	padding: 50px 50px 15px;
    margin-bottom: 40px;
    border: 1px solid rgba(225, 225, 225, .35);
    text-align: left;
}

.package:nth-child(1) {
	background-color: #f5f5f5;
    -webkit-box-shadow: 0 0 6px rgb(10 10 10 / 10%);
    -moz-box-shadow: 0 0 6px rgba(10, 10, 10, 0.1);
    box-shadow: 0 0 6px rgb(10 10 10 / 10%);
}

.packageTitle {
	font-weight: 400;
	letter-spacing: 0;
	margin-bottom: 20px;
	font-size: 1.3125rem;
    text-transform: uppercase;
}

.package sup {
	color: #999;
    font-size: 250%;
    font-weight: 400;
    top: -22px;
    right: 1px;
}

.packagePrice {
	font-size: 500%;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -1.5px;
    margin-left: 8px;
}

.packageDescription {
	margin-top: 10px;
    margin-bottom: 30px;
    font-size: 0.95rem;
    line-height: 1.3;
}

.package_button {
	width: 100%;
	padding: 0.75rem 0 !important;
	margin-top: 15px;
	margin-bottom: 35px;
	display: block;
	text-align: center;
	background-color: transparent;
	color: #fff;
	border-radius: 5px !important;
    background-color: #E5472D;
    border-color: #E5472D;
}

.package_button:hover {
    background-color: #E5472D;
    border-color: #E5472D;
    color: #fff;
    box-shadow: 3px 3px 10px #E5472D;
}

.pricingPlan {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricing {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.package hr {
	border-color: #f7f6f6;
    width: 100%;
    color: #aaa;
    background-color: #aaa;
    opacity: 0.5;
}

.packages .features {
	padding: 25px 0;
	border-top: 1px solid #aaa;
    width: 100%;
}

.package .feature {
	color: #000;
    font-size: 0.95rem;
    line-height: 1;
    font-weight: 400;
    padding: 14px 0;
    border-bottom: 1px solid #ccc;
    position: relative;
}

.feature span {
    max-width: 70%;
    font-size: 90%;
    line-height: 125%;
}

.featureEnabled {
	position: absolute;
	right: 6px;
	bottom: 12px;
	font-weight: 500;
    color: #00C18C;
}

.memberDiscountedStroked {
    position: relative;
    display: inline-block;
    max-width: 97px;
}

.memberDiscountedStroked::after {
    top: 40%;
    right: 0;
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    transform: rotate(5deg);
    background-color: rgba(0, 0, 0, 0.54);
}

.memberDiscountedStroked .packagePrice {
    font-size: 170%;
}

.memberDiscountedStroked sup {
    font-size: 100%;
    top: -6px;
}

.discountText {
    font-size: 11px;
    font-size: italic;
}

/* FAQs  */

.faqs {
	background-color: #fff;
}

.qaPanels {
	margin-top: 70px;
}

.faqs .question {
	text-align: left;
    font-size: 1.2rem;
    margin-bottom: 7px;
    display: block;
}

.faqs .answer {
    font-size: 1rem;
    display: block;
    padding-left: 20px;
    margin-top: 15px;
    font-weight: 300;
}


/* Overvew */

.section{
    padding:  100px 0;
}
.vertical_column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.cart_items{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.card_item{
    padding: 15px 0;
}
.about_text{
text-align: left;
}
.btn_large{
    margin-top: 50px;
}
.list_text{
    color: #00c087;
    margin-bottom: 0;
    font-weight: 700;
}
.list_text_grey{
    color: #222927;
    font-weight: 700;
}

.desktopShow {
	display: block !important;
}

.desktopHide {
	display: none !important;
}

section#futurefest {
    background-color: #0028A4;
}

/* Venue  */

.venue {
	background-color: #f5f5f5;
}

/*Banner*/

.banner{
    background-color: #E5472D;
    background-size: cover;
    background-position: bottom;
    padding-top: 80px;
}

.bannerFreelanceFestISL {
    background: rgb(229,71,45);
    background: linear-gradient(90deg, rgba(229,71,45,1) 30%, rgba(61,99,174,1) 78%);
    background-size: cover;
    background-position: bottom;
    padding-top: 80px;
}

.banner_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 50px 0;
}
.banner_right{
     display: flex;
     justify-content: flex-end;
     align-items: flex-end;
 }
 .banner_right img {
    max-width: 800px;
    margin: 20px 0;
 }
.img_center{
    align-items: center;
}
h1.banner_title{
    font-size: 48px;
    letter-spacing: -1px;
    font-weight: 800;
    line-height: 60px;
    margin-bottom: 35px;
    color: #fff;
}
div.banner_text{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 40px;
}


@media (max-width: 992px) {
    .header_logo {
        margin: 0 20px;
        max-width: 100%;
    }
    .header_logo img {
        width: 200px;
        height: 150px;
    }
}

@media (max-width: 768px){
    .cart_items{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .section{
        padding:  40px 0;
    }
    .btn_large{
        margin-top: 30px;
    }

    .timer .title {
    	width: 100%;
    	max-width: 100%;
    }

    .timerGrid {
    	flex-wrap: wrap;
    	justify-content: flex-start;
    }

    .count {
    	width: 100%;
    }

    .countDown {
    	font-size: 2.5rem;
    }

    .countInner {
    	margin-top: 30px;
    }

    .section_title_container {
    	width: 100%;
    	max-width: 100%;
    }

    .speaker {
    	width: 100%;
    }

    .scheduleItem {
    	width: 100%;
    }

    .tabPaneHead {
    	font-size: 0.875rem;
    	padding: 0 15px;
    }

    .package {
    	margin: 15px;
    	width: 100%;
    }

    .sponsor {
        width: 50%;
    }

    .desktopShow {
    	display: none !important;
    }

    .desktopHide {
    	display: block !important;
    }

    .menu_desktop {
        display: none;
    }

    .menu_mobile {
        display: block;
        text-align: left;
    }

    .menu_mobile .menu_item {
        margin-bottom: 7px;
    }

    .menu_mobile hr {
        margin: 30px 0 10px 0;
    }

    .menu_mobile .ant-drawer-header {
        text-align: right;
    }

    .menu_icon svg {
        width: 20px;
        height: 20px;
    }

    .menu_modal {
        display: block;
        color: #fff;
    }

    .header {
    	position: relative;
    	width: 100%;
    	height: auto;
    	z-index: inherit;
    	overflow: visible;
    }

    .menu_item a {
    	color: #E5472D !Important;
    }

    .header_btn button, .event-register-btn {
        display: none;
    }

    .banner {
        padding-top: 50px;
    }

    .banner_right img {
        max-width: 200px;
        margin: 0;
    }
    .banner_right{
        display: none;
    }
    h1.banner_title{
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 20px;
    }
    div.banner_text{
        font-size: 14px;
    }
    .banner_left{
        padding: 30px 0;
    }
    .header_logo span {
        font-size: 20px;
        text-align: left;
    }
}
