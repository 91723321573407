.gallery {
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

.gallery_column {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;
    max-width: 260px;
}

.image_wrapper {
    border-radius: 30px;
    overflow: hidden;
    width: 100%;
    margin: 10px 0;
}

.image_wrapper img {
    object-fit: cover;
    width: 100%;
    object-position: center;
}

.img_large {
    height: 400px;
}

.img_small {
    height: 320px
}

.img_medium {
    height: 220px
}
@media (max-width: 992px){
    .gallery_column {
        max-width: 40%;
    }
    .gallery{
        padding: 30px 0;
        justify-content: center;
    }
}
@media (max-width: 768px){
    .img_large {
        height: 250px;
    }

    .img_small {
        height: 200px
    }

    .img_medium {
        height: 150px
    }
}
