.issue {
	width: 100%;
}

.issues_header {
	display: flex;
	justify-content: space-between;
}

.issueTag {
	display: flex;
    gap: 15px;
    align-items: center;
}

.issueActions {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.issueActionButtons {
	background-color: transparent;
	color: #00c087;
}

.new_issue_button {
    padding-right: 30px;
    padding-left: 30px;
    height: 35px;
    font-size: 12px;
    max-width: 150px;
}

.newIssueSelect input {
    padding: 6px 0 8px 30px;
    line-height: 1.5715;
}

.newIssueSelectItem {
	text-align: left;
}

.issueHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.issueDescription {
	border-bottom: 3px solid #eee;
    padding-bottom: 25px;
    margin-top: 15px;
    line-height: 25px;
}

.commentBoxContainer {
	width: 100%;
	margin-top: 30px;
	border-top: 1px solid #eee;
	padding-top: 20px;
}

.commentBox {
	margin-top: 20px;
	width: 100%;
}

.comments {
	width: 100%;
}

.memberTag {
	font-size: 10px;
	    color: #fff;
	    background-color: #fc8900;
	    vertical-align: middle;
	    border: 1px solid #fc8900;
	    padding: 0 10px;
	    line-height: 16px;
}