.section {
	padding: 50px 0;
}

.section_title_container {
	max-width: 70%;
	text-align: left;
}

.featuredswiperContainer {
	padding: 0 50px;
}

.featuredLogo {
	min-height: 140px;
	max-height: 140px;
	display: flex;
	align-items: center;
	background-color: #fff;
}

.featuredTitle {
	font-size: 28px !important;
	line-height: 48px !important;
	margin: 0;
	margin: 50px 0;
}

.pr_items {
	display: flex;
	align-items: normal;
	justify-content: flex-start;
	max-width: 1280px;
	margin: auto;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 50px;
}

.pr_item {
	width: 33%;
	padding: 15px;
	background-color: transparent;
	margin-bottom: 15px;
}

.pr_details {
	text-align: left;
	margin: 15px 0;
}

.pr_details a {
	text-decoration: underline;
	color: #00000073;
}

.pr_details .Prtext {
	color: #00000073;
}

.pr_details h5 {
	color: #f04823;
}

.cover {
	max-width: 100%;
	height: 250px;
	overflow: hidden;
}

.cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.cover_video {
	height: auto;
}

.highlight_item_inner {
	display: flex;
	column-gap: 40px;
	margin-top: 50px;
	align-items: center;
}

.section.highlights {
	background-color: #00130e;
}

.section.highlights h1,
.section.highlights h2,
.section.highlights h3,
.section.highlights h4,
.section.highlights h5 {
	color: #fff;
}

.section.highlights h3 {
	font-size: 35px;
	font-weight: bold;
	line-height: 40px;
	margin-bottom: 10px;
}

.section.highlights h4 {
	font-size: 20px;
	margin-bottom: 5px;
	color: #eee;
	line-height: 20px;
}

.section.highlights h5 {
	font-size: 18px;
	color: #eee;
}

.section.highlights p {
	color: #eee;
	margin-top: 20px;
}

.swiperContainer {
	position: relative;
	margin-left: -50px;
	margin-right: -50px;
	margin-top: 30px;
}

.swiperContainer .swiperSlider {
	padding: 0 50px;
}

.videoPlayer {
	z-index: -5;
	pointer-events: none;
	height: 150px !important;
	width: 100% !important;
}

.focusVideoPlayer {
	min-width: 100%;
	min-height: 600px;
	z-index: 10;
	pointer-events: all;
}

.focuscover_video {
	width: 100%;
}

.highlights {
	display: flex;
	column-gap: 40px;
	flex-wrap: wrap;
}

.highlight {
	margin: 20px 0;
	cursor: pointer;
}

.highlight_details {
	margin-top: 10px;
	text-align: left;
}

.highlight .highlight_details h3 {
	font-size: 16px;
	margin: 0;
	line-height: 17px;
	margin-bottom: 5px;
	color: #eee;
}

.highlight .highlight_details h4 {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
	color: #ccc;
}

.highlight .highlight_details h5 {
	font-size: 12px;
	line-height: 14px;
	margin-bottom: 5px;
	color: #ccc;
}

.partners {
	background-color: #f5f5f5;
}

.benefit_items {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 1280px;
	margin: auto;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 50px;
}

.viewMorePartners {
	font-weight: bold;
	color: #fc8900;
	cursor: pointer;
	display: block;
	text-align: left;
	text-decoration: underline;
}

.viewAll {
	font-weight: bold;
	color: #fc8900;
	cursor: pointer;
	display: block;
	text-align: left;
	text-decoration: underline;
}

.packages {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
}

.package {
	width: 33%;
	margin-bottom: 15px;
	padding: 50px 25px 100px;
	margin-bottom: 40px;
	border: 2px solid rgba(225, 225, 225, 0.35);
	text-align: left;
	position: relative;
}

.packageActive {
	padding-bottom: 20px;
	padding-top: 20px;
}

.package:nth-child(2),
.package:nth-child(5),
.package:nth-child(8) {
	background-color: #f5f5f5;
	-webkit-box-shadow: 0 0 6px rgb(10 10 10 / 10%);
	-moz-box-shadow: 0 0 6px rgba(10, 10, 10, 0.1);
	box-shadow: 0 0 6px rgb(10 10 10 / 10%);
}

.packageImage {
	margin-bottom: 20px;
}

.packageImage img {
	max-width: 100px;
	margin: auto;
}

.packageTitle {
	font-weight: 400;
	letter-spacing: 0;
	margin-bottom: 20px;
	font-size: 1.3125rem;
	text-transform: uppercase;
}

.package sup {
	color: #999;
	font-size: 120%;
	font-weight: 400;
	vertical-align: sub;
	margin-right: 8px;
}

.packagePrice {
	font-size: 250%;
	line-height: 30px;
	font-weight: 500;
	letter-spacing: -1.5px;
	display: block;
}

.packageDescription {
	margin-top: 10px;
	margin-bottom: 30px;
	font-size: 0.95rem;
	line-height: 1.3;
}

.ctas {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	position: absolute;
	width: 100%;
	left: 0;
	padding: 25px;
	bottom: 0;
}

.cta_button {
	margin-top: 15px;
	margin-bottom: 35px;
	color: #fff;
	background-color: #00c087;
	border-color: #00c087;
}

.package_button.package_button_secondary {
	background-color: #a3a3a3;
	border-color: #a3a3a3;
}

.package_button,
.package_button:focus,
.package_button[disabled] {
	width: 100%;
	padding: 0.75rem 0 !important;
	margin-top: 15px;
	margin-bottom: 35px;
	display: block;
	text-align: center;
	color: #fff;
	border-radius: 5px !important;
	background-color: #00c087;
	border-color: #00c087;
}

.offerrings .package_button,
.offerrings .package_button:focus,
.offerrings .package_button[disabled] {
	margin: 0;
}

.package_button.active {
	background-color: #00130e;
	color: #fff;
}

.package_button:hover {
	background-color: #00c087;
	border-color: #00c087;
	color: #fff;
	box-shadow: 3px 3px 10px #00c087;
}

.pricingPlan {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.pricing {
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.package hr {
	border-color: #f7f6f6;
	width: 100%;
	color: #aaa;
	background-color: #aaa;
	opacity: 0.5;
}

.packages .features {
	padding: 25px 0;
	border-top: 1px solid #aaa;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.offerrings .features {
	border-width: 0px;
	padding: 0;
}

.offerrings h3 {
	text-align: left;
	font-weight: 600;
}

.package .feature {
	color: #000;
	font-size: 0.95rem;
	line-height: 1;
	font-weight: 400;
	padding: 14px 0;
	border-bottom: 1px solid #ccc;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.package .feature:last-child {
	border-bottom: 0;
}

.feature span {
	font-size: 90%;
	line-height: 125%;
}
.feature .featureTitle {
	flex-basis: calc(95% - 25px);
	line-height: 22px;
}

.feature .featureTitle span {
	margin-left: 5px;
}

.feature .featureEnabled {
	color: #00c18c;
	width: 25px;
	height: 25px;
	font-size: 20px;
}

.memberDiscountedStroked {
	position: relative;
	display: inline-block;
	max-width: 97px;
	line-height: 10px;
	margin-bottom: 10px;
}

.memberDiscountedStroked::after {
	top: 55%;
	right: 0;
	width: 100%;
	height: 2px;
	content: '';
	position: absolute;
	transform: rotate(5deg);
	background-color: rgba(0, 0, 0, 0.54);
}

.memberDiscountedStroked .packagePrice {
	font-size: 140%;
}

.memberDiscountedStroked sup {
	font-size: 80%;
	top: -6px;
}

.discountText {
	font-size: 11px;
	font-size: italic;
}

.qaPanels {
	margin-top: 50px;
}

.answer {
	text-align: left;
}

.packageActive .learnMore {
	margin-bottom: 0;
}

.learnMore {
	font-weight: 700;
	color: #fc8900;
	cursor: pointer;
	display: block;
	text-align: left;
	text-decoration: underline;
	margin-bottom: 20px;
}

@media (max-width: 768px) {
	.section {
		padding: 10px 0;
	}
	.section_title_container {
		max-width: 100%;
	}
	.package {
		width: 100%;
		padding: 25px;
	}

	.offerrings .package {
		padding-bottom: 100px;
	}

	.swiperContainer {
		position: relative;
		margin-left: 0;
		margin-right: 0;
		margin-top: 30px;
	}

	.swiperContainer .swiperSlider {
		padding: 0;
	}
}
